import PhuongTien from "./PhuongTien";
class ChuyenDi extends PhuongTien {
    constructor(
        idChuyenDi,
        guidChuyenDi,
        maChuyenDi,
        idLoTrinh,
        gioXuatBenDuKien,
        gioDenNoiDuKien,
        gioDenNoiThucTe,
        gioXuatBenThucTe,
        trangThaiChuyenDi
    ) {
        super();
        this.idChuyenDi = idChuyenDi;
        this.guidChuyenDi = guidChuyenDi;
        this.guidXe = "";
        this.maChuyenDi = maChuyenDi;
        this.idLoTrinh = idLoTrinh;
        this.gioXuatBenDuKien = gioXuatBenDuKien;
        this.gioDenNoiDuKien = gioDenNoiDuKien;
        this.gioDenNoiThucTe = gioDenNoiThucTe;
        this.gioXuatBenThucTe = gioXuatBenThucTe;
        this.trangThaiChuyenDi = trangThaiChuyenDi;
    }
    layDanhSachChuyenDi(thoiGianTimKiem) {
        const store = window.$Helper
            .Table({
                Id: "id",
                Url: window.$i18n.global.t("url.TimKiemChuyenDi"),
                UrlConfig: window.SP["API_ChuyenDi"],
                Stt: false,
            })
            .CustomStore(function (data) {
                console.log(
                    "🚀 ~ file: ChuyenDi.js ~ line 34 ~ ChuyenDi ~ data",
                    data
                );
            })
            .Post({ thoiGianTimKiem: thoiGianTimKiem });
        return store.Store;
    }
    async layDanhSachChoNgoiTheoSoDienThoai(maChuyenDi, soDienThoai) {
        if (!soDienThoai) {
            return new Error("Không có soDienThoai");
        }
        if (!maChuyenDi) {
            return new Error("Không có maChuyenDi");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.DanhSachChoNgoiTheoSoDienThoai"),
            {
                guidChuyenDi: this.guidChuyenDi,
                MaChuyenDi: maChuyenDi,
                SoDienThoai: soDienThoai,
            }
        ).Get();

        window.$loading.hide();
        return rs;
    }
    async layThongTinHKQuaChoNgoi(maChuyenDi, guidChoNgoi) {
        if (!maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!guidChoNgoi) {
            throw new Error("Không có guidChoNgoi");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.LayThongTinHKQuaChoNgoi"),
            {
                guidChuyenDi: this.guidChuyenDi,
                MaChuyenDi: maChuyenDi,
                GuidChoNgoi: guidChoNgoi,
            }
        ).Get();

        window.$loading.hide();
        return rs;
    }
    async layThongTinHKQuaQrCode(maChuyenDi, maDatCho, QrCodeData) {
        if (!maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.LayThongTinHanhKhachQuaQrCode"),
            {
                MaChuyenDi: maChuyenDi,
                MaDatCho: maDatCho,
                QrCodeData: QrCodeData,
            }
        ).Get();

        window.$loading.hide();
        return rs;
    }
    async xacNhanKhachXuongXe(maDatCho, maChuyenDi, tenCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.XacNhanKhachXuongXe")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
            tenCho: tenCho,
        });

        window.$loading.hide();
        return rs;
    }
    async khongSoDoCho_XacNhanKhachXuongXe(maDatCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.KhongSoDoCho_XacNhanXuongXe")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: this.maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
        });

        window.$loading.hide();
        return rs;
    }
    async ghePhu_XacNhanXuongXe(maDatCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.GhePhu_XacNhanXuongXe")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: this.maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
        });

        window.$loading.hide();
        return rs;
    }
    async xacNhanKhachLoChuyen(maDatCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.XacNhanKhachLoChuyen")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: this.maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
        });

        window.$loading.hide();
        return rs;
    }
    async soatVe(maDatCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.maChuyenDi) {
            throw new Error("Không có maChuyenDi");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.SoatVe")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: this.maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
        });

        window.$loading.hide();
        return rs;
    }
    async soatVeBangMaQr(maDatCho, ToaDo) {
        if (!maDatCho) {
            throw new Error("Không có maDatCho");
        }
        if (!this.guidXe) {
            throw new Error("Không có guidXe");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.DoiSoat(
            window.$i18n.global.t("url.SoatVeQuaQrCode")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
            maChuyenDi: this.maChuyenDi,
            maDatCho: maDatCho,
            guidXe: this.guidXe,
            toaDo: ToaDo, // chuwa cos
        });

        window.$loading.hide();
        return rs;
    }
    async xacNhanKetThucChuyenDi() {
        window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.XacNhanKetThucChuyenDi")
        ).Post({
            guidChuyenDi: this.guidChuyenDi,
        });

        window.$loading.hide();
        return rs;
    }
    async layGiaTienGoiYTheoDiemXuong(
        GuidChuyenDi,
        GuidChoNgoi,
        GuidDiemXuong
    ) {
        // window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.LayGiaTienGoiYTheoDiemXuong"),
            {
                GuidChuyenDi: GuidChuyenDi,
                GuidChoNgoi: GuidChoNgoi,
                GuidDiemXuong: GuidDiemXuong,
            }
        ).Get();
        // window.$loading.hide();
        return rs;
    }
    tongTienTheoChoDaChon(choDaChon) {
        let tongTien = 0;
        if (Array.isArray(choDaChon) && choDaChon.length > 0) {
            choDaChon.forEach((e) => {
                tongTien += e.Gia;
            });
        }
        return tongTien;
    }
    async layDanhSachLichSuChuyenDi(guidChuyenDi) {
        // window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.LayDanhSachLichSuChuyenDi"),
            { GuidChuyenDi: guidChuyenDi }
        ).Get();
        // window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
}
export { ChuyenDi as default };
